import React from 'react';
import Img from "gatsby-image"

const Header = ({image}) => {
    return (
            <div className="header" style={{height:'auto', overflow:'hidden'}}>
            <div style={{
                    position: 'absolute', zIndex: '5', width: '100%'}}>
                <h1 style={{
                    textAlign: 'right',
                    color: 'white',
                    padding: '20px 20px 0 20px',
                    fontSize: '5vw',
                    textShadow: '2px 0px 2px #004A8E, 0px 2px 2px #004A8E',
                    fontFamily: 'Lato'
                }}>Avec Shalee, <br/>Faites-vous votre expérience du cheval !</h1>
                <div style={{ textAlign:'right', minWidth: '100px'}}>
                        <a href="https://itunes.apple.com/fr/app/shalee/id1414309393?mt=8" className="appstore-link" >
                            <img alt="" src="https://yuka.io/wp-content/themes/fusion/images/appstore-badge.svg" />
                        </a><br/>
                        <a href="https://play.google.com/store/apps/details?id=com.tim0.shalee" className="playstore-link">
                            <img alt="" src="https://yuka.io/wp-content/themes/fusion/images/playstore-badge.svg" />
                        </a>
                    </div> 
                </div>  
                <Img fluid={image}/>
            </div>
    );
};

export default Header;
