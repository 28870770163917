import React, { Component } from 'react';
import { Link } from "gatsby"
import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import { graphql } from "gatsby"
import { Row, Col } from 'antd'
import Img from 'gatsby-image'
import SEO from '../components/seo'



import '../styles/global.scss'

class Index extends Component {
    constructor(props){
        super(props);
        this.state = {
            navbar: 'top',
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if(window.scrollY > 100){
            this.setState({navbar: 'no-top'})
        } else {
            this.setState({navbar: 'top'})
        }
    };

    render() {
        return (
            <Layout onScroll={this.handleScroll} position={this.state.navbar} currentPage='home'>
                <SEO title="Shalee, l'agent digital équestre" keywords={[`Shalee`,`Application`,`Mobile`,`Cheval`,`Chevaux`,`Club`,`Equestre`,`Equitation`,`Cavalier`]}/>
                <div style={{paddingTop: '100px'}}>
                    <Header image={this.props.data.imageOne.childImageSharp.fluid} />
                    <section>
                        <Row className="container"  type="flex" justify="space-around" align="middle">
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <h1 className="title" style={{textAlign: 'center'}}>Shalee, l'agent digital équestre</h1>
                                <p style={{textAlign: 'center'}}>Shalee est une application mobile qui permet aux cavaliers (débutants et confirmés) de bénéficier de tous les services de leurs clubs à portée de main.</p>
                            </Col>
                        </Row>
                    </section>
                    <section className="bg-dark link" style={{maxHeight:'500px'}}>
                        <Link to="/cavalier">
                        <Row className="container"  type="flex" justify="space-around" align="middle" gutter={50}>
                            <Col xs={34} sm={34} md={12} lg={12} xl={12} className="second">
                                <div style={{maxWidth:"300px", margin:'auto',minWidth: '210px'}}>
                                    <Img fluid={this.props.data.imageAppli.childImageSharp.fluid} style={{marginBottom: `-50px`}}/>
                                </div>
                            </Col>
                            <Col xs={34} sm={34} md={12} lg={12} xl={12} className="first">
                                <h1 className="title" >Tu es cavalier ?</h1>
                                <p>Viens profiter des évènements équestres !</p>
                            </Col>
                        </Row>
                        </Link>
                    </section>
                    <section className="link">
                    <Link to="/club">
                        <Row className="container"  type="flex" justify="space-around" align="middle">
                            <Col xs={34} sm={34} md={12} lg={12} xl={12}>
                                <h1 className="title" >Vous êtes un club ?</h1>
                                <p>Venez digitaliser votre club équestre !</p>
                            </Col>
                            <Col xs={34} sm={34} md={12} lg={12} xl={12}>
                                <Img fluid={this.props.data.imageClub.childImageSharp.fluid} style={{minWidth: '300px'}}/>
                            </Col>
                        </Row>
                        </Link>
                    </section>
                    <Footer/>
                </div>
            </Layout>
        );
    }
}

export default Index;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "two.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    imageClub: file(relativePath: { eq: "mac-club.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
    }
    imageAppli: file(relativePath: { eq: "presentation_shalee.png" }) {
        childImageSharp {
            fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
            }
        }
    }
  }
`